import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, DefaultDropdown } from '@makerdao/ui-components-core'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageWithTOC from '../components/pageWithTOC'
import Markdown from '../components/markdown'
import { getGenericPageTitle, getMessage, languages, messages } from '../i18n'
import { FormattedMessage as FM } from 'react-intl'
import LangDropdown from '../components/langDropdown'
import UpCaret from '../images/imported/icons/upCaret.svg'

const Title = styled.h1`
  font-size: 3.2rem;
  margin-bottom: 1.3rem;
  line-height: 4.4rem;
  font-weight: 500;
  letter-spacing: 0.04rem;
`

const Button = styled.div`
  display: inline-flex;
  align-items: center;
  color: #a6a6a6;
  border: 1px solid #a6a6a6;
  border-radius: 33px;
  padding: 0.7rem 1.8rem 0.9rem;
  font-size: 1.6rem;
  cursor: pointer;
  letter-spacing: 0.3px;
  transition: all 0.1s ease;
  flex-shrink: 0;
  text-decoration: none;
  white-space: nowrap;

  :hover {
    border-color: #000;
    color: #000;
  }
`

const LangDropdownWrapper = styled(Box)`
  display: inline-block;

  svg {
    fill: #bdbdbd;
  }

  :hover {
    ${Button} {
      border-color: #000;
      color: #000;

      svg {
        fill: #333;
      }
    }
  }
`

const LangTrigger = ({ children, ...props }) => (
  <Button {...props}>
    {children}
    <UpCaret style={{ transform: 'rotateZ(180deg)', marginLeft: '12px' }} />
  </Button>
)

const StyledDropdown = styled(DefaultDropdown)`
  margin-top: 4px;
`

const WhitepaperMarkdownStyle = styled(Box)`
  max-width: 760px;

  h1 {
    margin-bottom: 1.8rem;
  }

  h2 {
    margin-bottom: 1.8rem;
  }

  h1,
  h2,
  h3,
  h4 {
    strong {
      font-weight: 500;
    }
  }

  strong {
    font-weight: 500;
  }

  p {
    line-height: 2.8rem;
    font-size: 1.8rem;
  }

  li {
    font-size: 1.8rem;
    margin-top: 10px;
  }

  .footnotes li {
    margin-top: 0;
  }

  sup {
    vertical-align: baseline;
    position: relative;
    top: -7px;
  }

  @media (max-width: 768px) {
    .annotation {
      /* Prevent page from jumping around when loading. */
      left: -410px;
    }
  }
`

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const findMdByLang = (data, lang) =>
  data.allMarkdownRemark.edges
    .map(edge => edge.node)
    .find(node => node.frontmatter.lang === lang)

const Whitepaper = ({ pageContext: { locale }, data }) => {
  const markdown = findMdByLang(data, locale) || findMdByLang(data, 'en')
  const whitepaperLangs = data.allMarkdownRemark.edges.map(
    edge => edge.node.frontmatter.lang
  )
  const dropdownLangs = languages.filter(({ value }) =>
    whitepaperLangs.includes(value)
  )

  useEffect(() => {
    // correct out of bounds annotations
    const annotations = document.querySelectorAll('.annotation')
    const paper = document.querySelector('#paper')

    const debouncedHandleResize = debounce(function handleResize() {
      const boundary = paper.offsetLeft + paper.clientWidth + 5
      annotations.forEach(ann => {
        const rightSideX = ann.parentElement.offsetLeft + ann.clientWidth
        ann.style.left =
          rightSideX > boundary ? `${boundary - rightSideX}px` : 0
      })
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)
    debouncedHandleResize()

    // fix links inside annotations not working
    const anchors = document.querySelectorAll('.annotation a')
    anchors.forEach(a => {
      a.onclick = e => {
        e.preventDefault()
        let win = window.open(a.href, '_blank')
        win.focus()
      }
    })

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return (
    <Layout
      locale={locale}
      fixedMobileMenu={true}
      meta={{
        title:
          messages[locale]['whitepaper.meta.title'] ||
          getGenericPageTitle('Whitepaper', locale),
        description: getMessage('whitepaper.meta.description', locale),
      }}
      themeOverrides={{ highlightColor: '#1AAB9B' }}
    >
      <PageWithTOC
        headings={markdown ? markdown.headings : []}
        separatorsAt={[13]}
      >
        <Box>
          <Title>{markdown.frontmatter.title}</Title>
        </Box>
        <Box m="23px 0 28px" style={{ position: 'relative', height: '37px' }}>
          <div style={{ position: 'absolute', zIndex: 1 }}>
            {/* Fix buttons hiding behind first h1 */}
            {locale === 'en' && (
              <Button
                style={{ marginRight: '17px' }}
                as="a"
                href="/whitepaper/White Paper -The Maker Protocol_ MakerDAO’s Multi-Collateral Dai (MCD) System-FINAL- 021720.pdf"
              >
                <FM id="whitepaper.download-btn" />
              </Button>
            )}
            <LangDropdownWrapper>
              <LangDropdown
                languages={dropdownLangs}
                selected={markdown.frontmatter.lang}
                Trigger={LangTrigger}
                DropdownItems={StyledDropdown}
                placement="bottom"
                hitBoxMargin="12px"
                offset={null}
              />
            </LangDropdownWrapper>
          </div>
        </Box>

        <WhitepaperMarkdownStyle fontSize="1.7rem" id="paper">
          <Markdown
            dangerouslySetInnerHTML={{
              __html: markdown.html,
            }}
          />
        </WhitepaperMarkdownStyle>
      </PageWithTOC>
    </Layout>
  )
}

export default Whitepaper

export const query = graphql`
  query Whitepaper {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/whitepaper/whitepaper-dai**" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            lang
          }
          headings {
            value
            depth
          }
        }
      }
    }
  }
`
